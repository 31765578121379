<template>
  <!-- 权限管理页面 -->
  <div>
    <div class="table-layout">
      <el-row :gutter="20">
        <el-form
          ref="authForm"
          :model="authForm"
          label-width="90px"
          :inline="true"
        >
          <el-row class="btnRow">
            <el-col :span="6" class="elrow">
              <el-form-item
                label="唯一编码"
                prop="iditifyId"
                style="padding-left: 10px"
              >
                <el-input
                  v-model="authForm.iditifyId"
                  size="mini"
                  clearable
                  style="width: 180px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" class="elrow">
              <el-form-item label="在线状态" prop="xsxz">
                <el-select
                  v-model="authForm.status"
                  size="mini"
                  style="width: 200px"
                >
                  <el-option
                    v-for="item in onlineOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <search-button
              v-show="showAll"
              :showAll="showAll"
              @clearAll="clearAll"
              @exportEvent="exportEvent"
              @searchEvent="searchSenser"
              @closepop="closepop"
            ></search-button>
            <SearchButton
              v-show="!showAll"
              :showAll="showAll"
              @clearAll="clearAll"
              @exportEvent="exportEvent"
              @searchEvent="searchSenser"
              @closepop="closepop"
            ></SearchButton>
          </el-row>
        </el-form>
      </el-row>
      <!-- 分割线 -->
      <el-divider></el-divider>
      <!-- 新增按钮区域 -->
      <el-row style="margin-bottom: 10px">
        <el-col :span="1.5" class="elrow">
          <el-button
            style="width: 80px"
            type="text"
            icon="el-icon-plus"
            size="mini"
            class="editBtn"
            @click="add"
            >新增权限</el-button
          >
        </el-col>
        <el-col :span="1.5" class="elrow">
          <el-button
            style="width: 80px"
            type="text"
            icon="el-icon-refresh"
            size="mini"
            class="editBtn"
            @click="editSensor"
            >修改</el-button
          >
        </el-col>
        <el-col :span="1.5" class="elrow">
          <el-button
            style="width: 80px"
            type="text"
            class="deleteBtn"
            icon="el-icon-delete"
            size="mini"
            @click="delSensor"
            >删除</el-button
          >
        </el-col>
        <!-- <el-col :span="1.5" class="elrow">
            <el-button
              style="width: 80px"
              type="text"
              icon="el-icon-refresh"
              size="mini"
              class="editBtn"
              @click="bangdingSensor"
              >授权权限</el-button
            >
          </el-col> -->
      </el-row>
      <!-- 权限数据表格 -->
      <el-table
        :header-cell-style="{ background: '#00000075', 'text-align': 'center', color: '#fff' }"
        :data="roleTable"
        style="width: 100%; margin-bottom: 20px"
        :cell-style="{ background: '#00000075','text-align': 'center' }"
        row-key="attribute_id"
        height="500px"
        border
        @selection-change="handleSelectionChangeNode"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="序号">
          <template slot-scope="scope">{{ scope.row.attribute_id }} </template>
        </el-table-column>
        <el-table-column prop="parent_id" label="父级" v-if="false"> </el-table-column>
        <el-table-column prop="attribute_name" label="名称"> </el-table-column>
        <el-table-column prop="attribute" label="类型"> </el-table-column>
        <el-table-column prop="remarks" label="解释"> </el-table-column>
      </el-table>
    </div>
    <!-- 4、新增、修改弹窗区域-->
    <!--新增单条角色信息管理-->
    <div>
      <el-dialog
        title=""
        :append-to-body="true"
        :visible.sync="singleAddDialogVisible"
        custom-class="custom-dialog"
        width="30%"
        center
      >
        <div class="openDigl">
          <div class="item1">
            <br />
            <el-form
              label-position="left"
              label-width="100px"
              :model="authForm"
            >
              <el-form-item label="权限类型:" :required="true">
                <el-select
                  v-model="authForm.attribute"
                  @change="selectTypeChange"
                  :popper-append-to-body="false"
                  ><el-option
                    v-for="item in typeSetOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="父级权限:"
                v-if="authForm.attribute != 'menu'"
                :required="true"
              >
                <el-select
                  v-model="authForm.parent_id"
                  :popper-append-to-body="false"
                  ><el-option
                    v-for="item in authOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="权限名称:" :required="true">
                <el-input
                  class="btn-text"
                  v-model="authForm.attribute_name"
                ></el-input>
              </el-form-item>
              <el-form-item label="说明:" :required="true">
                <el-input
                  class="btn-text"
                  v-model="authForm.remarks"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  class="btn-text"
                  @click="handleClick"
                  >{{ addorEdittext }}</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getAllAuths,
  addNewAuth,
  editAuth,
  delAuth,
  getAllAuthListAll,
} from "@/api/index";
import SearchButton from "./tool/button.vue";
/* 引入菜单管理api接口 */

export default {
  name: "SysMenu",
  components: {
    SearchButton,
  },
  data() {
    return {
      roleTable: [],
      /* 弹窗区域字段 */
      dialogVisible: false,
      dialogTitle: "",
      // 控制菜单类型单选按钮是否可用
      typeDisabled: false,
      typeDisabled0: false,
      typeDisabled1: false,
      typeDisabled2: false,
      // 控制弹窗确定按钮是否可用
      saveBtnDisabled: false,
      singleAddDialogVisible: false,
      addorEdittext: "新增",
      selctionNodeRows: [],
      nodeids: [],
      showAll: true,
      //查询条件form
      authForm: {
        parent_id: 0,
        attribute_name: "",
        attribute: "menu",
        remarks: "",
      },
      onlineOptions: [
        { label: "userAdmin", value: "1" },
        { label: "admin", value: "2" },
        { label: "user", value: "3" },
      ],
      typeSetOptions: [
        { label: "菜单", value: "menu" },
        { label: "目录", value: "catalog" },
        { label: "按钮", value: "btn" },
      ],
      typeOptions: [
        {
          label: "目录",
          value: 0,
        },
        {
          label: "菜单",
          value: 1,
        },
        {
          label: "按钮",
          value: 2,
        },
      ],
      authOptions: [],
    };
  },
  methods: {
    /* 获取角色 */
    getRoleList() {
       getAllAuths().then(res=>{
      if (res.code == 200) {
        this.roleTable = this.formatTree(res.data, 0);
      } else {
        this.$message.error(res.error);
      }
       }).catch(err => {
        console.log(err)
       })
    },
    // 后端反的格式转化成数组嵌套
    formatTree(arr, attribute_id) {
      let newArr = [];
      arr.forEach((item) => {
        if (item.parent_id == attribute_id) {
          item.children = this.formatTree(arr, item.attribute_id);
          newArr.push({
            ...item,
          });
        }
      });
      return newArr;
    },
    // 选中某个标签加载此标签对应的列
    selectTypeChange(value) {
      //   // 如果要录入的是菜单
      if (value == "menu") {
        this.authForm.parent_id = 0;
      } else {
        // 加载父级权限列表，选择目录或菜单
        // 显示这个目录菜单
        getAllAuthListAll().then((res) => {
          if (res.data.length > 0) {
            res.data.forEach((v) => {
              if (v.attribute != "btn") {
                this.authOptions.push({
                  label: v.attribute_name,
                  value: v.attribute_id,
                });
              }
            });
          }
        });
      }
    },
    add() {
      // 新增传感器弹窗信息
      this.authForm = {};
      this.addorEdittext = "新增"
      this.singleAddDialogVisible = true;
    },
    // 单条节点管理
    handleSelectionChangeNode(selection) {
      this.selctionNodeRows = selection;
      this.nodeids = selection.map((item) => item.id); // 需要根据数据情况调整id名称
    },
    // 新增传感器信息
    addSensor() {
      addNewAuth(this.authForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("添加成功！");
          this.getRoleList();
        } else {
          this.$message.error(res.error);
        }
        this.singleAddDialogVisible = false;
      });
    },
    // 修改站点信息弹窗
    editSensor() {
      if (this.nodeids.length !== 1) {
        this.$message.warning("请选择一条数据进行修改操作！");
      } else {
        this.authForm.attribute_name = this.selctionNodeRows[0].attribute_name;
        this.authForm.attribute = this.selctionNodeRows[0].attribute;
        this.authForm.remarks = this.selctionNodeRows[0].remarks;
        this.singleAddDialogVisible = true;
        this.addorEdittext = "修改";
      }
    },
    // 批量删除或删除单个传感器
    delSensor() {
      if (this.nodeids.length == 0) {
        this.$message.warning("请至少选择一条数据进行删除操作！");
      } else {
        let arr = []
        this.selctionNodeRows.forEach(v => {
          arr.push(v.attribute_id)
        })
        delAuth({ attributes: arr })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("删除成功");
              // 请求表格数据
              this.getRoleList();
            } else {
              this.$message.error(res.error);
            }
          })
          .catch(() => {});
      }
    },
    handleClick() {
      if (this.addorEdittext == "新增") {
        this.addSensor();
      } else {
        this.relationEdit();
      }
    },
    // 修改变电站
    relationEdit() {
      // 使用链接put 修改
      let params = {
        attribute_id: this.selctionNodeRows[0].attribute_id,
        parent_id: this.authForm.parent_id,
        attribute_name: this.authForm.attribute_name,
        attribute: this.authForm.attribute,
        remarks: this.authForm.remarks,
      };
      editAuth(params).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功！");
          // 关闭弹窗 加载列表
          this.singleAddDialogVisible = false;
          this.getRoleList();
        }
      });
    },
    closepop() {
      this.showAll = !this.showAll;
    },
    // 绑定传感器和设备
    bangdingSensor() {
      // 打开弹窗 绑定的弹窗加载设备列表，选中一个设备绑定即可
    },
    searchSenser() {
      console.log("您进入了父组件，点击了查询之后可以得到：" + this.authForm);
    },
    // 重置
    clearAll() {},
    exportEvent() {
      this.$message.success("导出按钮");
    },
  },
  //当页面加载时获取数据
  created() {
    // 获取所有角色
    this.getRoleList();
  },
};
</script>

<style lang="scss" scoped>
@import "./table.scss";
.el-tree /deep/ .el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  color: #fff200;
}
//有子节点 且未展开
.el-table/deep/ .el-icon-arrow-right:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #fff200;
  font-size: 16px;
  background-size: 16px;
}
//有子节点 且已展开
.el-table/deep/ .el-table__expand-icon--expanded {
  .el-icon-arrow-right:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #fff200;
    font-size: 16px;
    background-size: 16px;
  }
}
//没有子节点
.el-tree
  /deep/.el-tree-node__expand-icon.is-leaf::before
  .el-table/deep/.el-table__placeholder::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 6px;
  border-color: transparent transparent transparent #fff200;
  font-size: 16px;
  background-size: 16px;
}
.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 10px 0;
}
.dialog-item {
  background-color: rgba(27, 42, 65, 0.8);
}
::v-deep .custom-dialog .el-dialog__body {
  height: auto;
  overflow: hidden;
  background-color: #062b7cc2;
  border: 1px solid #409eff;
  color: aliceblue;
  overflow: auto;
}

.openDig {
  display: flex;
  background-color: #285ed4c2;
}
.custom-dialog {
  ::v-deep .el-card__body,
  .el-main {
    padding: 5px;
  }

  ::v-deep .el-image__inner {
    margin-top: 20%;
  }

  .dga_data {
    width: 100%;
  }

  ::v-deep .el-tree {
    position: relative;
    cursor: pointer;
    background: transparent;
    color: #fff;
  }
  ::v-deep .el-tree-node__content {
    &:hover {
      background: #6f9ec9;
    }
  }
  ::v-deep .el-tree-node.is-current > .el-tree-node__content {
    background-color: rgb(20, 107, 107) !important;
  }
  ::v-deep .el-form-item__label {
    font-size: 18px;
    color: #041634;
    line-height: 40px;
  }
  ::v-deep .el-dialog__header {
    padding: 0px !important;
    background-color: #041634 !important;
  }

  ::v-deep .el-dialog__body {
    background-color: #041634;
  }

  ::v-deep .el-dialog__footer {
    background-color: #041634;
  }

  ::-webkit-scrollbar {
    width: 1px;
  }
  //滚动条滑块
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgb(3, 33, 92);
    transition: 0.3s ease-in-out;
  }
}
</style>